@import "variables";
@import "mixins";

.page {
  &-content {
    padding: 32px 48px;
  }

  &-title {
    font-size: $font-xxl;
    margin-bottom: 32px;
    font-weight: 700;
    color: $color-primary-darkest;
  }
}

.btn-submit {
  width: 17rem;
  height: 40px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: $color-primary-darkest;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $color-neutral-lightest;
  border: none;
  cursor: pointer;
  margin-left: 12px;
  outline: none !important;
  margin-right: 16%;
  margin-bottom: 1rem;

  &:hover {
    background-color: $color-primary-medium;
  }

  &-disabled {
    background: $color-neutral-dark;
    pointer-events: none;

    &:hover {
      background: $color-neutral-dark;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  height: 44px;
  justify-content: center;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;

  &-primary {
    display: flex;
    gap: 8px;
    background-color: $color-primary-darkest;
    padding: 10px 24px;

    &:hover {
      transition: 0.5s;
      background-color: $color-primary-dark;
    }

    i,
    span {
      font-size: 14px;
      color: $color-neutral-white;
    }

    i {
      width: 20px;
      height: 20px;
      font-size: 16px;
    }
  }

  &-link {
    color: $color-primary-dark;
    font-weight: 600;
    font-size: $font-md;
    text-decoration: none;
    margin-right: 24px;
    margin-bottom: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      color: $color-support-medium;
      transition: 0.5s;
    }
  }
}

.actions-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-primary-dark;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: $font-md;
  gap: 8px;
  min-width: 11.0625rem;
  height: 2rem;
  background: $color-primary-light;
  border: 1.5px solid $color-support-lightest;
  border-radius: 0.25rem;
  padding: 10px 24px 10px 20px;
  cursor: pointer !important;

  &:hover {
    background-color: $color-primary-lightest;
    border: 1.5px solid $color-primary-medium;
    color: $color-primary-medium;

    i {
      color: $color-primary-medium;
    }
  }

  i {
    font-size: $font-xl;
  }
}

.form-control {
  width: 85%;
  border: 2px solid $color-neutral-light;
  color: $color-neutral-dark;
  background-color: $color-neutral-lightest;

  &.email {
    text-transform: lowercase !important;
  }

  &__success {
    border: 2px solid $color-secondary-medium;

    &:focus {
      border: 2px solid $color-secondary-medium !important;
      box-shadow: none !important;
    }
  }

  &__error {
    border: 2px solid $color-helper;
    color: $color-helper;

    &:focus {
      border: 2px solid $color-helper !important;
      box-shadow: none !important;
    }
  }
}

.modal-header {
  border: none;

  .modal-title {
    font-size: $font-xxl;
    color: $color-neutral-darkest;
    font-weight: 700;
  }

  .close {
    padding: 0;
    margin: 0;
    outline: none;

    &:hover {
      color: $color-helper;
    }

    .icon__close {
      font-size: 30px;
    }
  }

  @include for-phone-only {
    .modal-title {
      font-size: $font-lg;
    }

    .close {
      .icon__close {
        font-size: 24px;
      }
    }
  }
}

.modal-header,
.modal-body {
  background: $color-neutral-white;
  padding: 24px;
  border-radius: 16px;

  @include for-phone-only {
    padding: 16px 16px 36px;
    border-radius: 0;
  }
}

.modal-footer {
  background: $color-neutral-white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.modal-body {
  padding-top: 0;

  @media only screen and (max-height: 768px) and (min-width: 576px) {
    max-height: 450px;
  }
}

.modal-content {
  border: none;
  border-radius: 16px;
}

.modal-dialog {
  &.modal-xl {
    max-width: 940px !important;
    max-height: 600px !important;
  }

  &.modal-md {
    max-width: 650px !important;
  }

  &.modal-sm {
    max-width: 464px !important;
  }


  &.register-tutor-number {
    max-width: 700px !important;
  }

  @include for-phone-only {
    margin: 0;
    max-width: 100vw !important;

    &.confirm-message {
      height: 100%;
      top: 0;
      bottom: 0;
      max-width: 100vw !important;
      padding: 8px;
      background-color: rgba(0, 0, 0, 0.3);
      align-items: self-end;
      z-index: 500;

      .modal-content {
        border-radius: 16px;
        height: auto !important;
      }

      .modal-header {
        padding-bottom: 16px;
      }

      .modal-header,
      .modal-body {
        border-radius: 50%;
      }
    }
  }
}

.modal-message {
  width: 480px;
  height: 216px;
  border-radius: 8px;
  background-color: $color-primary-lightest;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-validate__label {
  color: $color-neutral-dark;

  > span {
    color: $color-helper;
  }
}

.section-product-content {
  width: 94%;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 2rem;
  max-height: 80%;

  @include for-phone-only {
    margin-top: 0;
    width: 100%;
    max-height: 100%;
  }
}

.products-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 1rem;
  border-radius: 8px;
  width: 460px;
  min-height: 130px;
  margin: 0.25rem 1.5rem 1.5rem 2rem;
  background-color: $color-primary-lightest;
  cursor: pointer;

  &:hover {
    background-color: $color-primary-light;
  }

  & .product-card {
    display: flex;

    > .product-card-image {
      margin: 0.25rem 0 0 0.25rem;

      > img {
        min-width: 70px;
        max-height: 110px;
        margin-top: 0.5rem;
      }
    }

    & .product-card-content {
      width: 100%;
      margin-left: 2rem;
      padding-top: 0.5rem;

      & .card-title {
        font-size: $font-lg;
        color: $color-neutral-darkest;
        font-weight: 700;
        line-height: 19.36px;
      }

      & .card-tags {
        font-size: $font-sm;
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-right: 0;
        margin-bottom: 0.5rem;

        & .container-tags {
          padding: 1px;
          background-color: $color-support-lightest;
          padding: 2px 8px 2px 8px;
          border-radius: 2px;
          gap: 10px;
          display: flex;
          align-items: center;
          flex-direction: row;
          height: fit-content;

          > caption {
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            font-weight: 400;
            line-height: 18px;
            color: $color-primary-dark;
          }
        }
      }
    }
  }

  .change-products-more-details {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: $font-md;
    color: $color-primary-dark;
    margin-top: 1rem;

    > a {
      display: flex;
      align-items: center;

      > .link-title {
        font-weight: 600;
        font-size: $font-sm;
        line-height: 16px;
        margin-left: 6px;
        color: $color-primary-dark;
        white-space: nowrap;
      }
    }

    &:hover {
      color: $color-primary-darkest;
    }
  }

  @include for-phone-only {
    margin: 16px;
    padding: 0;
    width: 93%;
    min-height: 100%;
    height: fit-content;
    overflow: hidden;

    &.first {
      margin-top: 0;
    }

    & .product-card {
      width: 100%;
      padding: 8px 12px !important;

      > .product-card-image {
        width: 88px;
        text-align: center;

        > img {
          min-width: auto;
          width: 56px;
          height: 81px;
        }
      }

      & .product-card-content {
        margin-left: 12px;

        & .card-title {
          font-size: $font-md;
          margin-bottom: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 90%;
        }

        @media only screen and (max-width: 420px) {
          & .card-title {
            max-width: 84%;
          }
        }

        & .card-tags {
          width: 55vw;
        }
      }
    }

    .change-products-more-details {
      width: 104px;
      margin-top: 12px;

      > a {
        i {
          display: none;

          > .link-title {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.tags-clinics {
  &__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__content-tags {
    width: auto !important;
    height: 22px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding: 0.125rem 0.5rem;
    grid-gap: 6px;
    gap: 6px;
    z-index: 99;
    background-color: $color-secondary-light;
    margin-right: 0.5rem;

    &.conditions-clinic {
      background-color: $color-support-lightest;

      > caption {
        color: $color-primary-dark;
      }
    }

    > caption {
      font-size: $font-sm;
      line-height: 18px;
      font-weight: 400;
      color: $color-secondary-darkest;
      padding: 0;
    }
  }
}

.additional-recomentations {
  .section-text {
    color: $color-neutral-dark;
    font-weight: 400;
    line-height: 24px;
    font-size: $font-md;
  }

  textarea {
    width: 100%;
    height: auto;
    border: 1.5px solid $color-neutral-light;
    border-radius: 8px;
    padding: 1rem;
    color: $color-neutral-dark;

    &:focus {
      outline: none;
    }
  }
}

.tab {
  color: $color-support-lightest;
  font-size: $font-xxl;
  rotate: 90deg;
  margin-bottom: 0.375rem;
}

.offcanvas {
  width: 550px;
  position: fixed;
  right: 0;
  height: 100%;
  top: 0;
  background-color: $color-neutral-white;

  > .modal-header {
    border: none;
    padding: 1.5rem;
    height: 50px;

    > .modal-title {
      font-size: $font-xxl;
    }
  }

  @include for-phone-only {
    width: 100vw;

    > .modal-header {
      padding: 24px 16px;

      > .modal-title {
        font-size: $font-lg;
      }
    }
  }
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  height: 90%;

  @include for-phone-only {
    height: 93%;
  }
}

.notification-icon {
  position: relative;
  color: $color-primary-dark;
  font-size: $font-xxl;

  .badge-notification {
    color: #fe7f01 !important;
    background-color: #fe7f01 !important;
    right: 1px;
    top: 2px;
    width: 9px;
    height: 9px;
  }

  @include for-phone-only {
    color: inherit;

    .badge-notification {
      width: 7.5px;
      height: 7.5px;
    }
  }
}

@media screen and (max-width: 480px) {
  .actions-button {
    font-size: $font-sm;
    font-weight: 600;
    line-height: 16px;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    gap: 8px;

    > i {
      font-size: $font-md;
    }
  }
}

.cards-orientation {
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  border-radius: 0.5rem;
  background-color: $color-neutral-white;
  border: 1.5px solid $color-primary-light;
  width: 240px;
  height: 89px;
  margin-right: 1.5rem;

  > .content-cards {
    display: flex;
    align-items: center;

    > .icon_card {
      width: 32px;
      height: 32px;
      color: $color-support-medium;
    }

    > .info-card-container {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 1rem;
      align-items: normal;

      > .type-card {
        font-size: $font-md;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        color: $color-neutral-dark;
      }

      > .qtd-card {
        font-size: $font-xxvl;
        font-weight: 700;
        line-height: normal;
        color: $color-primary-medium;
      }
    }
  }
}

.cards-orientation-mobile {
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  flex: 1 0 0;
  align-self: stretch;
  border: 1.5px solid $color-primary-light;
  background-color: $color-neutral-white;
  width: 160px;
  height: 74px;
  border-radius: 0.5rem;
}

.card-orientation-title {
  color: $color-neutral-dark;
  font-size: $font-sm;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
}

.icon_card-mobile {
  width: 20px;
  height: 20px;
}

.qtd-card-mobile {
  font-size: 24px;
  font-weight: 700;
  color: $color-primary-medium;
}

.container-options-selectors {
  width: 618px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  border-radius: 24px;
  background-color: $color-neutral-white;
  border: 1px solid $color-primary-light;
  padding: 1.5rem;
  margin-bottom: 2rem;

  &.selected {
    border-color: $color-support-medium;
  }

  &__title {
    font-size: $font-xxl;
    font-weight: 700;
    line-height: normal;
    color: $color-neutral-dark;
  }

  @include for-tablet {
    margin-top: 0;
    margin-bottom: 24px;
    width: 328px;
    padding: 16px;
    gap: 16px;

    &__title {
      font-size: $font-lg;
    }
  }
}

.description-options-selector {
  font-size: $font-md;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $color-neutral-dark;
}

@include for-large-screen {
  .cards-orientation {
    width: 290px;
  }
}

@media only screen and (min-width: 1360px) and (max-width: 1380px){
  .container-options-selectors {
    width: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .cards-orientation {
    width: 22vw;
  }
  .container-options-selectors {
    width: 100%;
  }
}
