@mixin for-phone-only {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin for-tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin for-large-screen {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}
