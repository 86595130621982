/* Colors: */
//Primary Colors
$color-primary-darkest: #004976;
$color-primary-dark: #0069d9;
$color-primary-medium: #007bff;
$color-primary-light: #e1f0ff;
$color-primary-lightest: #f5faff;

//Secondary Colors
$color-secondary-darkest: #2b7066;
$color-secondary-dark: #00ae94;
$color-secondary-medium: #00dcaf;
$color-secondary-light: #b2f3e9;
$color-secondary-light-2: #dcfef9;
$color-secondary-lightest: #e9fcf9;

$color-tertiary-medium: #ff8200;
$color-tertiary-light: #ffdfbf;
$color-tertiary-lightest: #ffb466;

//Support colors
$color-support-medium: #5eacff;
$color-support-light: #99caff;
$color-support-lightest: #c8e3ff;
$color-support-neutral-lightest: #eeeeee;
$color-success: #00dcaf;
$color-helper: #ff557d;
$color-helper-light: #ffeded;
$color-alert: #fff1d5;
$color-alert2: #fff0d4;

//Neutral Colors
$color-neutral-darkest: #1f2326;
$color-neutral-dark: #4a6171;
$color-neutral-medium: #8ba4b5;
$color-neutral-light: #becad2;
$color-neutral-lightest: #fafafa;
$color-neutral-white: #fff;
$color-neutral-black: #000;

//Others
$accent-2: #ffa3b9;
$accent-3: #cc4564;
$alert-2: #ff9b33;
$alert-3: #fff0d4;
$alert-4: #ffb466;
$alert-5: #fffaf0;

/* Fonts */
$font-sm: 12px;
$font-md: 14px;
$font-lg: 16px;
$font-xl: 18px;
$font-xxl: 20px;
$font-icon: 24px;
$font-xxvl: 28px;
$font-xxxl: 48px;

/* img : */

$img-background-credentials-container: '/assets/images/background/credentials-background.png';
$img-background-credentials-mobile: '/assets/images/background/credentials-background-mobile.png';
$img-background-line-dog: '/assets/images/line-dog.png';
$img-background-line-cat: '/assets/images/line-cat.png';
$img-background-line-dog-mobile: '/assets/images/line-dog-mobile.png';
$img-background-line-cat-mobile: '/assets/images/line-cat-mobile.png';
$img-social-1: '/assets/images/social-1.png';
$img-social-2: '/assets/images/social-2.png';
$img-social-3: '/assets/images/social-3.png';
$img-social-1-mobile: '/assets/images/social-1-mobile.png';
$img-social-2-mobile: '/assets/images/social-2-mobile.png';
$img-social-3-mobile: '/assets/images/social-3-mobile.png';
$img-soon-1-mobile: '/assets/images/background/soon-background-mobile.png';
$img-soon-2-mobile: '/assets/images/background/top-soon-background-mobile.png';
$img-calculator-mobile: '/assets/images/background/calculator-background-mobile.png';
