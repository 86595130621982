@import "variables";
@import "mixins";

.toast-container {
  .ngx-toastr {
    padding: 16px 16px 16px 50px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    color: $color-neutral-dark;
    border-radius: 8px;
    max-width: 500px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    gap: 16px;

    @media only screen and (min-width: 576px) {
      animation: .5s slide-left;
    }

    &:hover {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .toast-close-button {
    color: $color-neutral-dark;
    font-size: 24px;
    width: 14px;
    height: 14px;
    margin-bottom: 11px;
    margin-right: 8px;
    font-weight: normal;
    text-shadow: none;

    span {
      font-family: math;
    }

    &:hover {
      opacity: 0.8;
    }
  }


  @include for-phone-only {
    left: 50%;
    transform: translate(-50%, -50%);
    top: initial;
    bottom: 66px;
    max-width: 300px;
    width: 100%;

    .ngx-toastr {
      padding: 8px 16px;
      border-radius: 100px;
      background-image: none;
      animation: .3s slide-top;
      .mensagem-toast {
        width: 100%;
        text-align: center;
        font-size: $font-sm;
      }
    }

    .toast-close-button {
      display: none;
    }
  }
}

.toast {
  &-success {
    background-color: $color-secondary-light;
    border: 1px solid $color-success;
    background-image: url("/assets/icons/check-circle.svg");
  }

  &-warning {
    background-color: $color-alert;
    border: 1px solid $alert-2;
    background-image: url("/assets/icons/exclamation-circle.svg");
  }

  &-error {
    background-color: $color-helper-light;
    border: 1px solid $accent-2;
    background-image: url("/assets/icons/x-circle.svg");

  }

  @include for-phone-only {
    &-success {
      .mensagem-toast {
        color: $color-secondary-darkest;
      }
    }

    &-error {
      .mensagem-toast {
        color: $accent-3;
      }
    }
  }
}


@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

@keyframes slide-top {
  from {
    margin-bottom: -100%;
  }
  to {
    margin-bottom: 0%;
  }
}
