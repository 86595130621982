/* You can add global styles to this file, and also import other style files */
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css');
@import './assets/sass/premiervet-design.scss';
@import './assets/sass/variables.scss';
@import 'node_modules/ngx-toastr/toastr';
@import './assets/sass/toast.scss';
@import '../node_modules/font-awesome/css/font-awesome.css';

* {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1360px) {
    overflow: inherit;
  }

  @media screen and (min-width: 1360px) {
    overflow: hidden !important;
  }
}

body:has(app-background) {
  overflow: auto;

  @media screen and (max-width: 1360px) {
    overflow: auto;
  }

  @media screen and (min-width: 1360px) {
    overflow: auto !important;
  }
}

.label-spinner {
  font-size: 16px;
  color: #5eacff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ng-sidebar__content {
  padding: 0;
  overflow: auto;
  height: calc(100% - 6rem);
  width: 100%;
  display: flex;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 32px;
  font-size: 2rem;
}

@import '~@ng-select/ng-select/themes/default.theme.css';

@function colour($value) {
  @return map-get($colours, $value);
}

$colours: (
  box-primary: #f5faff,
  box-title: #004274,
  box-border: #e1f0ff,
  btn-primary: #0069d9,
  btn-primary-hover: #007bff,
  btn-secondary: #00ae94,
  btn-secondary-hover: #00dcaf,
  link: #007bff,
  input-border-color: #ced4da,
  grayscale71: #4a6171,
  grayscaleb5: #8ba4b5,
  grayscaleEa: #d9e3ea
);

:root {
  --fieldsetError: #ff577d;
  --fieldsetSuccess: #00dcaf;
  --fieldsetSuccessBackground: #e9fcf9;
}

a {
  color: colour(link);
}

.text-title {
  color: colour(box-title);
}

.text-link {
  color: colour(link);
}

.text-gray-light {
  color: rgb(199, 199, 199);
}

.box-form {
  padding: 20px 20px 30px 20px;
  border-radius: 15px;
  border: 1px solid colour(box-border);
  background-color: colour(box-primary);
}

.box-form h4 {
  color: colour(box-title);
  font-size: 1rem;
}

.box-form p,
.box-form span {
  font-size: 0.8rem;
}

.box-form-white {
  padding: 20px 20px 30px 20px;
  border-radius: 15px;
  border: none;
  background-color: white;
}

.box-form-white h4 {
  color: colour(box-title);
  font-size: 1rem;
}

.box-form-white p,
.box-form-white span {
  font-size: 0.8rem;
}

.box-footer {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
}

.box-button {
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

.box-radius {
  border-radius: 15px;
}

.box-shadow {
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.175) !important;
}

.btn {
  border-radius: 0.5rem;
  border: 1px solid transparent;
}

.btn-primary {
  color: white;
  background-color: colour(btn-primary);
}

.btn-primary:hover {
  background-color: colour(btn-primary-hover);
}

.btn-secondary {
  color: white;
  background-color: colour(btn-secondary);
}

.btn-secondary:hover {
  background-color: colour(btn-secondary-hover);
}

.form-group label,
.form-group span {
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
}

.input-icon-transparent input {
  border-left: none;
}

.input-icon-transparent > input:focus {
  outline-style: none;
  box-shadow: none;
  border-color: colour(input-border-color);
}

.input-icon-transparent .input-group-prepend .input-group-text {
  background-color: transparent;
  border-right: none;
}

.has-error {
  border: 1px solid #d8496a;
  color: #d8496a;
  background-color: #fff6f8 !important;
}

.has-error > *,
.has-error > *:focus {
  background-color: #fff6f8 !important;
}

select.vpet {
  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff !important;
  background: url('/assets/images/arrow-select.png') no-repeat right;
  background-position:
    calc(100% - 10px) calc(1em + -1px),
    calc(100% - 10px) calc(1em + 2px),
    100% 0;
  background-size:
    13px 8px,
    5px 15px,
    2.5em 2.5em;
  overflow: hidden;
  font-size: 0.9rem;
}

ng-select .ng-select-container {
  border: 1px solid #d9e3ea !important;
}

ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
  min-height: 84px;
  width: 0px;
}

.ng-select .ng-select-container .ng-value-container {
  align-items: flex-start;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: 83px;
  text-align: start;
  display: flex;
  justify-content: flex-start;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #00ae94 !important;
  border: #00826e;

  span {
    color: #ffffff;
  }
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-right: 1px solid #00ae94;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: #00ae94;
}

.ng-select .ng-clear-wrapper {
  display: none;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: 0;
  border-color: transparent;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-style: none;
}

.ng-select .ng-clear-wrapper {
  margin-top: 5px;
}

.badge-primary {
  border: solid 1px #00826e;
  background-color: #00ae94;
  color: #000000;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400px;
  margin-right: 5px;
}

.badge-secundary {
  border: solid 1px #e0a800;
  background-color: #ffc107;
  color: #684e00;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400px;
  margin-right: 5px;
}

.badge-last {
  border: solid 1px #754aa1;
  background-color: #854bbc;
  color: #ffffff;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400px;
  margin-right: 5px;
}

.badge {
  font-weight: 400;
}

// style da modal com icones no campo de input

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .img-avatar-novo {
    width: 91px;
    height: 91px;
    margin-top: 47px;
  }

  .span-avatar-novo {
    font-size: 0.8rem;
    color: #004274;
    border: #707070;
    margin-bottom: 20px;
    margin-top: 8px;
  }

  .modal-idade {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    select {
      width: 68px;
    }

    p {
      width: 55px;
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      color: #868e96;
      border: #707070;
      font-weight: 200;
      margin-bottom: 0px;
    }
  }

  .check-falecido {
    margin-top: 29px;
    display: flex;

    input {
      width: 18px;
      height: 18px;
      border: solid 1px #d9e3ea !important;
      margin-bottom: 0px;
      margin-right: 5px;
    }

    p {
      font-size: 16px;
      font-family: 'Roboto', sans-serif;
      color: #4a6171;
      border: #707070;
      font-weight: 200;
      margin-bottom: 0px;
    }
  }

  .container-modal {
    height: auto;

    .box-tags {
      width: 100%;
      display: flex;
      height: auto;
      flex-wrap: wrap;
      align-items: center;

      .tag-grupo-1 {
        background: #ffc107;
        width: fit-content;
        height: 22px;
        margin-left: 5px;
        border-radius: 5px;
        border: solid 1px #e0a800;
        display: flex;
        align-items: center;
        margin-top: 5px;

        .tags-btn {
          justify-content: center;
          align-items: center;
          padding: 3px;
        }

        span {
          color: #886600;
          font-size: 12px;
          font-family: 'Roboto', sans-serif;
          border: #707070;
          font-weight: 500px !important;
          margin-bottom: 3px;
        }

        button {
          height: 21px;
          background-color: transparent;
          font-size: 12px;
          color: #886600;
          outline: none;
          border: none;
          font-weight: 500px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .tag-grupo-2 {
        background: #00ae94;
        width: fit-content;
        height: 22px;
        margin-left: 5px;
        border-radius: 5px;
        border: solid 1px #2b7066;
        display: flex;
        align-items: center;
        margin-top: 5px;

        .tags-btn {
          align-items: center;
          padding: 3px;
        }

        span {
          color: #2b7066;
          font-size: 12px;
          font-family: 'Roboto', sans-serif;
          border: #707070;
          font-weight: 500px !important;
          margin-bottom: 3px;
        }

        button {
          height: 21px;
          background-color: transparent;
          font-size: 12px;
          color: #2b7066;
          outline: none;
          border: none;
          font-weight: 500px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .tag-grupo-3 {
        background: #854bbc;
        width: fit-content;
        height: 22px;
        margin-left: 5px;
        border-radius: 5px;
        border: solid 1px #754aa1;
        display: flex;
        align-items: center;
        margin-top: 5px;

        .tags-btn {
          align-items: center;
          padding: 3px;
        }

        span {
          color: #ffffff;
          font-size: 12px;
          font-family: 'Roboto', sans-serif;
          border: #707070;
          font-weight: 500px !important;
          margin-bottom: 3px;
        }

        button {
          height: 21px;
          background-color: transparent;
          font-size: 12px;
          color: #ffffff;
          outline: none;
          border: none;
          font-weight: 500px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .modal-tags {
    padding: 25px 15px 5px;
    display: flex;
    flex-direction: column;

    p:first-child {
      font-size: 16px;
      color: #004274;
      border: #707070;
      font-family: 'Roboto';
      margin-bottom: 0px;
      font-weight: 400;
    }

    p {
      font-size: 14px;
      color: #4a6171;
      border: #707070;
      font-family: 'Roboto';
      margin-bottom: 0px;
    }
  }

  label {
    font-size: 13px;
    color: #5c6871;
    font-weight: 600;
  }

  span {
    font-size: 13px;
    color: #d8496a;
  }

  select {
    border-radius: 5px;
    border: solid 1px #d9e3ea;
    height: 38px;
    background: #ffffff;
    font-size: 16px;
  }

  select::placeholder {
    color: #d9e3ea;
    font-size: 16px;
  }

  .box-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 26px;

    .btn-cancelar {
      width: 48%;
      background-color: #ffffff;
      color: #0069d9;
      border: solid 1px #0069d9;
      border-radius: 5px;
      height: 40px;
    }

    .btn-salvar {
      width: 48%;
      background-color: #0069d9;
      color: #ffffff;
      border: solid 1px #0069d9;
      border-radius: 5px;
      height: 40px;
    }
  }

  ::placeholder {
    color: #d9e3ea;
    font-size: 16px;
  }

  .input-whithIcon {
    position: relative;
    width: auto !important;

    input[type='text'] {
      padding-left: 30px;
    }

    input[type='email'] {
      padding-left: 30px;
    }

    img {
      position: absolute;
      left: 0;
      top: 3px;
      padding: 9px 8px;
    }
  }
}

.box-header {
  display: flex;
  text-align: center;

  p {
    width: 100%;
    font-size: 20px;
    color: #003761;
  }

  .close {
    color: #003761;
  }
}

// fim do estilo das modais

.tooltip-inner {
  background-color: #fff;
  border: 1px solid #0069d9;
  color: #004274 !important;
  opacity: 1;
  font-weight: 500;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #0069d9;
}

bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #0069d9;
}

/* estilo das tabs da página de extrato de pontos só funcionaram aqui no estilo geral */
.container-tabs .nav-tabs .nav-item.show .nav-link,
.container-tabs .nav-tabs .nav-link.active {
  color: #007bff;
  font-weight: bold;
  text-transform: uppercase;
}

.container-tabs .nav-tabs .nav-link {
  font-size: 12px;
  color: #8ba4b5;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 12px;
  padding-bottom: 12px;
}

.container-tabs .active.nav-item {
  border-top: solid 3px #007bff;
  border-radius: 0.25em;
}

.titulo-toast {
  font-size: 1.4em;
  font-weight: bold;
}

.mensagem-toast {
  font-size: 0.85em;
}

/* #region field-validate*/
.fieldset {
  position: relative;
  padding-bottom: 24px;
  overflow: hidden;
}

.p-0 .fieldset {
  padding: 0;
}

.show-overflow .fieldset {
  overflow: unset;
}

.input-error .input-wrapper .label-wrap .label-text {
  color: var(--fieldsetError) !important;
}

.input-error .input-wrapper ~ .p-input-bar,
.input-error .input-wrapper.outlined,
.input-error .input-wrapper {
  border: 1px solid var(--fieldsetError);
}

.input-error .input-wrapper input,
.input-error .input-wrapper textarea {
  caret-color: var(--fieldsetError);
  color: var(--fieldsetError);
}

.input-success .input-wrapper .label-wrap .label-text {
  color: var(--fieldsetSuccess) !important;
}

.input-success .input-wrapper ~ .p-input-bar,
.input-success .input-wrapper.outlined,
.input-success .input-wrapper {
  border: 1px solid var(--fieldsetSuccess);
  background-color: var(--fieldsetSuccessBackground) !important;
}

.input-success .input-wrapper input,
.input-success .input-wrapper textarea {
  caret-color: var(--fieldsetSuccess);
  background-color: var(--fieldsetSuccessBackground);
}

.input-success .focused .input-wrapper.outlined {
  border: 1px solid var(--fieldsetSuccess);
}

.input-success .input-wrapper:hover.outlined {
  box-shadow: inset 0 0 0 1px var(--fieldsetSuccess);
}

.input-wrapper {
  display: inline-flex;
  align-items: baseline;
  width: 100%;
  padding: 0.75em 0.75em 0 0.75em;
  background-color: white !important;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
  transition: box-shadow 100ms ease-in;
}

.isDisabledInputClass {
  background-color: #f4f4f4 !important;
  border: 1px solid #becad2 !important;
  input {
    color: #8ba4b5 !important;
  }
}

.disabled {
  opacity: 0.5;
}

.disabled .input-wrapper {
  opacity: 0.5;
  border: none;
  filter: brightness(75%);
  pointer-events: none;
}

.disabled .input-wrapper.outlined {
  opacity: 0.75;
  border: none;
  background-color: rgb(219, 210, 205);
  pointer-events: none;
}

.fieldset label {
  white-space: nowrap;
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  color: $color-neutral-dark;
  overflow: hidden;
}

.readonly .input-wrapper {
  border: none;
}

.readonly .input-wrapper .form-field {
  pointer-events: none;
}

.input-wrapper.outlined {
  background-color: transparent;
  border: 2px solid $color-neutral-light;
  padding: 0.5em;
  height: 40px;
  border-radius: 0.25rem;
}

.input-error .input-wrapper:hover.outlined {
  box-shadow: inset 0 0 0 1px var(--fieldsetError);
}

.input-wrapper:hover.outlined {
  box-shadow: inset 0 0 0 1px var(--fieldsetOutline);
}

.focused .input-wrapper.outlined {
  border: 2px solid $color-primary-medium;
  box-shadow: inset 0 0 0 1px var(--mc-primary);
  outline: none;
  color: $color-neutral-dark;
  background-color: $color-neutral-lightest;
}

.input-error .focused .input-wrapper.outlined {
  border: 1px solid var(--fieldsetError);
}

.fieldset label {
  transition: 50ms ease-in;
}

.focused label {
  color: $color-neutral-dark;
}

.input-error label {
  color: var(--fieldsetError);
}

.input-wrapper .input-content {
  display: flex;
  flex-direction: row;
}

.input-wrapper .form-field {
  display: block;
  position: relative;
  flex: auto;
  width: 100%;
}

.input-wrapper .suffix,
.input-wrapper .prefix {
  white-space: nowrap;
  flex: none;
  position: relative;
}

.input-wrapper .suffix {
  position: absolute;
  right: 5px;
  top: 35px;
}

.input-wrapper input,
.input-wrapper select {
  max-height: 20px;
}

.input-wrapper input,
.input-wrapper textarea,
.input-wrapper select {
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  border: none;
  outline: none;
  box-sizing: content-box;
  resize: vertical;
  color: $color-neutral-dark;
  padding: 0;
  caret-color: var(--mc-primary);
}

.helper-text {
  width: 100%;
  text-align: right;
  position: absolute;
  font-size: 13px;
  transform: translateY(10%);
  color: var(--fieldsetError);
  animation: helperTextAnim 0.5s;
  user-select: none;
  font-family: 'Roboto', sans-serif;
  white-space: nowrap;
}

.helper-text i {
  font-size: 20px;
  user-select: none;
}

.helper-text.error {
  color: var(--fieldsetError);
}

@keyframes helperTextAnim {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }

  to {
    opacity: 1;
    transform: translateY(10%);
  }
}

/**endregion*/

.button-password {
  border: none;
  position: relative;
  background: transparent;
  outline: none !important;

  > .icon-password {
    font-size: 18px;
    color: $color-primary-medium;
  }
}

button,
i {
  outline: none !important;
}

@media screen and (max-width: 767px) {
  .back-drop {
    background: transparent !important;
  }
}

.modal-additional-info {
  position: absolute;
  box-sizing: border-box;
  bottom: 1rem;
  left: 0.5rem;
  right: 0.5rem;
  animation: modal-slide-bottom-up 250ms linear;
}

@keyframes modal-slide-bottom-up {
  0% {
    opacity: 0;
    bottom: -10px;
  }

  100% {
    opacity: 1;
    bottom: 1rem;
  }
}

/*Data Tables*/
/* #region Table */
.p-table {
  width: 100%;
  box-sizing: border-box;
  border-collapse: collapse;
}

.p-table.table-text-centered td,
.p-table.table-text-centered th {
  text-align: center;
}

.p-table thead {
  border-bottom: 1px solid var(--defaultDividerColor);
}

.p-table thead.p-table-header-sticky::after {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: 1px solid var(--defaultDividerColor);
}

.p-table .p-table-header.small th {
  height: 0;
}

.p-table .p-table-header.th-break th {
  white-space: normal;
}

.p-table .p-table-header th {
  user-select: none;
  position: relative;
  height: 48px;
  font-size: 16px;
  text-align: left;
  padding: 0 0.75rem;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  border: 0;
  color: $color-neutral-dark;
}

.p-table .p-table-body tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  border-color: $color-support-lightest;
}

.p-table .p-table-body tr:hover td {
  background: #f5faff;
}

.p-table.no-lines .p-table-body tr:not(:last-child),
.p-table.no-lines .p-table-header {
  border-bottom: none !important;
}

.p-table .p-table-body td {
  height: 55px;
  padding: 0 0.75rem;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  font-style: normal;
  color: $color-neutral-dark;
  border: 0;
  vertical-align: middle !important;
}

.p-table .p-table-body td button,
.p-table .p-table-body td a {
  vertical-align: middle;
}

.p-table .p-table-body.td-break td {
  white-space: normal;
}

.p-table .table-expanded-content {
  padding: 0 1rem;
  overflow: hidden;
  height: 100%;
}

.p-table .table-expanded-content.hidden {
  height: 0%;
}

.p-table .table-expanded-row td {
  height: unset;
  padding: 0;
}

.table-expanded-row {
  border-bottom: 1px solid var(--defaultDividerColor) !important;
}

.p-table .table-expanded-row {
  border: 0;
}

.table-expanded-row-trigger {
  cursor: pointer;
}

.p-table .table-expanded-content.hidden {
  height: 0px;
}

.table-expanded .p-table tr:not(.table-expanded-row) {
  border: 0 !important;
}

.p-column-sortable i {
  font-size: 18px;
}

.sorting i {
  opacity: 1;
}

.p-table .p-table-header-sort {
  cursor: pointer;
}

.p-table .p-table-header-sort i {
  opacity: 0;
  position: absolute;
  right: 5px;
  font-size: 16px;
  top: 50%;
  transition: 0.3s cubic-bezier(0, 0.48, 0.36, 1);
  transition-property: opacity, transform;
}

.p-column-sortable {
  cursor: pointer;
}

.p-table .p-table-footer td {
  padding: 0 0.75rem;
  height: 56px;
  text-align: left;
}

.p-table .p-table-header-sticky {
  border: 0;
  background-color: $color-neutral-white;
  position: sticky;
  z-index: 1;
  top: 0;
}

.p-table .p-table-footer-sticky {
  background-color: $color-neutral-white;
  position: sticky;
  bottom: 0;
}

.spacer {
  flex-grow: 1;
}

.dContents {
  display: contents !important;
}

.table-header {
  background-color: $color-primary-lightest;
  border-radius: 4px;
  padding: 8px 6px 8px 16px;
}

@media screen and (max-width: 576px) {
  .fieldset label {
    white-space: normal;
    line-height: inherit;
  }

  .helper-text.error {
    font-size: $font-sm;
    line-height: 18px;
    font-weight: 400;
  }
}

/* #endregion Table */

/* #region PWA*/
.modal-pwa {
  z-index: 1;
  width: 92%;
  position: fixed;
  align-items: center;
  bottom: 14%;
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  background-color: $color-primary-light;
  border-radius: 12px;
  font-weight: 600;

  .close-button {
    display: flex;
    align-content: flex-start;
    background: transparent;
    border-style: none;

    i {
      font-size: $font-xxl;
    }
  }

  .pvet-logo {
    padding: 8px 0px 0px 0px;
    display: flex;
    margin-right: 2px;
    align-content: space-between;
    align-items: flex-start;
  }

  .notification-message {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    .icon {
      width: 50px;
      height: 50px;
      margin: 0.125rem 0.5rem 0 0;
    }

    .message {
      width: 80%;
      font-size: $font-sm;
      font-family: 'Roboto', sans-serif;
    }
  }
}

.ios-menu {
  width: 10px;
  margin-right: 2px !important;
}

/* #endregion pwa */

.clinical-nutrition-message-box {
  background-color: #fffaf0;
  color: #76797a;
  border-left: solid 5px #ffb466;
  font-size: 14px;
  font-style: normal;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.clinical-nutrition-message-icon {
  font-size: 25px;
  padding-right: 20px;
  color: #ffc282;
}

/* #region swiper */

.swiper-button-disabled {
  opacity: 0% !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ff9c35 !important;
}

.swiper-button-prev {
  background-image: url('/assets/icons/gradual-exchange/arrow_left.svg') !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next {
  background-image: url('/assets/icons/gradual-exchange/arrow_right.svg') !important;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

/* #endregion swiper */

.modal.fade.show[aria-labelledby='cookie'] {
  justify-content: center;
}

.modal {
  z-index: 4000;
}

.cc-window {
  max-width: 400px !important;
  width: auto !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.cc-message-container {
  .cc-message {
    padding-top: 24px !important;
    padding-bottom: 0px !important;
    margin-right: 0px !important;

    p {
      span {
        color: #4a6171;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 0px;
        margin-top: 20px;
      }
    }
  }
}

.cc-compliance {
  a {
    border-radius: 5px !important;
  }
}

.cc-window {
  border-radius: 10px;
}
